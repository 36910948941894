<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Map</div>
                </div>
            </div>
        </div>

        <div :class="`c-map has-active-${activeView} is-content-type-${componentData.content_type}`">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 medium-6 cell">
                        <div class="c-map__container">
                            <div
                                v-if="componentData.content_type == 'content' && parks && parks.length > 1"
                                class="c-map__toggles-wrapper">
                                <div class="c-toggle-buttons">
                                    <div
                                        class="c-toggle-buttons__container"
                                        :class="{ [`is-button-${button}`]: button }">
                                        <label
                                            class="c-toggle-buttons__button-container"
                                            @click="[(button = 'left'), (activeView = 'map')]">
                                            <span
                                                class="c-toggle-buttons__button"
                                                :class="{ 'is-active': button == 'left' }">
                                                Map
                                            </span>
                                        </label>

                                        <label
                                            class="c-toggle-buttons__button-container"
                                            @click="[(button = 'right'), (activeView = 'list')]">
                                            <span
                                                class="c-toggle-buttons__button"
                                                :class="{ 'is-active': button == 'right' }">
                                                List
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="c-map__wrapper" :class="{ 'is-active': activeView == 'map' }">
                                <ClientOnly>
                                    <GoogleMap
                                        class="s-google-map"
                                        :api-key="config.public.gmApiKey"
                                        :center="mapCenter"
                                        mapId="77b0ffd5256de7fa"
                                        ref="mapRef"
                                        :map-type-control="false"
                                        :zoom="mapZoom">
                                        <MarkerCluster :options="{ renderer: mapClusterRenderer }">
                                            <template v-for="(park, index) in parks" :key="index">
                                                <Marker
                                                    v-if="park.map"
                                                    :key="index"
                                                    :options="{
                                                        title: park.title ? park.title : 'Holgates Holiday Park',
                                                        icon: {
                                                            scaledSize: {
                                                                width: 75,
                                                                height: 75,
                                                            },
                                                            url: mapPin,
                                                        },
                                                        position: {
                                                            lat: park.map.lat,
                                                            lng: park.map.lng,
                                                        },
                                                    }">
                                                    <InfoWindow>
                                                        <PartialsLocationCard
                                                            :title="park.title"
                                                            :address="park.address"
                                                            :holiday_items="park.map_list_holidays"
                                                            :ownership_items="park.map_list_ownership"
                                                            :landing_page="park.landing_page" />
                                                    </InfoWindow>
                                                </Marker>
                                            </template>
                                        </MarkerCluster>
                                    </GoogleMap>
                                </ClientOnly>
                            </div>

                            <div
                                v-if="parks && parks.length > 1"
                                class="c-map__locations"
                                :class="{ 'is-active': activeView == 'list' }">
                                <PartialsLocationCard
                                    v-for="(park, index) in parks"
                                    :key="index"
                                    :title="park.title"
                                    :address="park.address"
                                    :holiday_items="park.map_list_holidays"
                                    :ownership_items="park.map_list_ownership"
                                    :landing_page="park.landing_page" />
                            </div>
                        </div>
                    </div>

                    <div class="small-12 medium-6 cell">
                        <div v-if="componentData.content_type == 'content'" class="c-map__content-wrapper">
                            <template v-if="componentData.title">
                                <h2
                                    v-if="componentData.settings.heading_level == 'h2'"
                                    class="c-map__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h2>
                                <h3
                                    v-else-if="componentData.settings.heading_level == 'h3'"
                                    class="c-map__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></h3>

                                <div
                                    v-else
                                    class="c-map__title wow animate__animated animate__fadeIn"
                                    v-html="componentData.title"></div>
                            </template>

                            <div
                                v-if="componentData.content"
                                class="c-map__content s-wp-cms wow animate__animated animate__fadeIn"
                                v-html="componentData.content"></div>

                            <div
                                v-if="componentData.links"
                                v-for="(link, index) in componentData.links"
                                :key="index"
                                class="c-map__links">
                                <NuxtLink
                                    noPrefetch
                                    :to="link.link.url"
                                    :target="link.link.target"
                                    class="c-map__link wow animate__animated animate__fadeIn">
                                    <span v-html="link.link.title"></span>
                                </NuxtLink>
                            </div>
                        </div>

                        <div v-else class="c-map__address-bubble">
                            <div class="c-map__address-bubble-title" v-html="parks[0].title"></div>

                            <div
                                v-if="parks[0].address"
                                class="c-map__address-bubble-address"
                                v-html="parks[0].address"></div>

                            <NuxtLink
                                v-if="parks[0].telephone_number"
                                class="c-map__address-bubble-telephone"
                                :to="`tel:${parks[0].telephone_number}`">
                                <i class="fa-solid fa-phone"></i>
                                <span v-html="parks[0].telephone_number"></span>
                            </NuxtLink>

                            <div
                                v-if="componentData.links"
                                v-for="(link, index) in componentData.links"
                                :key="index"
                                class="c-map__links">
                                <NuxtLink
                                    noPrefetch
                                    :to="link.link.url"
                                    :target="link.link.target"
                                    class="c-map__link wow animate__animated animate__fadeIn">
                                    <span v-html="link.link.title"></span>
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import mapPin from "~/assets/icons/map-pin.svg?no-inline";
    import clusterBackground from "~/assets/icons/map-cluster-background.svg?no-inline";
    import { GoogleMap, InfoWindow, MarkerCluster, Marker } from "vue3-google-map";

    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const config = useRuntimeConfig();
    const button = ref("left");
    const mapRef = ref(null);
    const parks = ref([]);
    const mapCenter = {
        lat: 54.17690293784177,
        lng: -2.8355178744270915,
    };
    const mapZoom = ref(15);
    const activeView = ref("map");

    const mapClusterRenderer = {
        render: ({ count, position }) =>
            new google.maps.Marker({
                label: {
                    color: "white",
                    fontSize: "50px",
                    fontFamily: "foco",
                    fontWeight: "700",
                    text: String(count),
                },
                position,
                icon: {
                    url: clusterBackground,
                    scaledSize: new google.maps.Size(75, 75),
                },
                // adjust zIndex to be above other markers
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                gridSize: 10,
            }),
    };

    const getParks = async (parkIds = null) => {
        let parksGroup = parkIds;

        // console.log("Park IDs: ", parkIds);

        if (parkIds && props.componentData.content_type == "content") {
            if (parkIds.length > 1) {
                parksGroup = parkIds.map((park) => park.ID).join(",");
            } else {
                parksGroup = parkIds[0].ID;
            }
        }

  
        const { data } = await useFetch(`/api/wp/parks/parks`, {
            query: {
                acf_format: "standard",
                park_ids: parksGroup,
            },
        });

        // console.log("Park Data: ", data.value);
        parks.value = data.value;
    };

    if (props.componentData.content_type == "address") {
        await getParks(props.componentData.park_single.ID);
    } else {
        await getParks(props.componentData.park ? props.componentData.park : null);
    }

    watch(
        () => mapRef.value?.ready,
        (ready) => {
            if (!ready) return;

            const bounds = new window.google.maps.LatLngBounds();

            parks.value.forEach((marker) => {
                if (marker.map) {
                    bounds.extend({
                        lat: marker.map.lat,
                        lng: marker.map.lng,
                    });
                }
            });

            mapRef.value.map.fitBounds(bounds);

            // Ensure map zoom is suitably zoomed out
            mapRef.value.map.addListener("bounds_changed", () => {
                const zoom = mapRef.value.map.getZoom();

                if (zoom > mapZoom.value) {
                    mapRef.value.map.setZoom(mapZoom.value);
                }
            });
        },
    );

    // console.log("Component Data: ", props.componentData);
    // console.log("Parks: ", parks.value);
</script>
